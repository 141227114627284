<template>
  <div class="internal-invoice-listing">
    <v-layout class="my-2">
      <v-flex md3>
        <v-menu
          v-if="false"
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              placeholder="Dates Range"
              hide-details
              solo
              :disabled="invoiceLoading"
              :loading="invoiceLoading"
              v-on:click:clear="clearFilter('dates')"
              clearable
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              class="mr-2"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="invoiceLoading"
            :loading="invoiceLoading"
            v-on:change="getInvoices"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
        <DateRangePicker
          placeholder="Data Range"
          v-model="dates"
          v-on:update:model-value="getInvoices()"
          v-on:click:clear="clearFilter('dates')"
          class="mr-2"
        ></DateRangePicker>
      </v-flex>
      <v-flex md3>
        <div class="d-flex">
          <v-text-field
            v-model.trim="search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :loading="invoiceLoading"
            :disabled="invoiceLoading"
            color="cyan"
            @keydown.enter="getInvoices()"
            @keydown.tab="getInvoices()"
          ></v-text-field>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            tile
            depressed
            :disabled="invoiceLoading"
            v-on:click="getInvoices()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            depressed
            :disabled="invoiceLoading"
            v-on:click="getInvoices()"
            color="cyan white--text"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </div>
      </v-flex>
      <v-flex md1> </v-flex>
    </v-layout>
    <ListingTable :columnCount="6" :rowData="rowData">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Invoice #</th>
            <th class="simple-table-th">Related #</th>
            <th class="simple-table-th">Basic Detail</th>
            <th class="simple-table-th">Dates</th>
            <th class="simple-table-th">Amount</th>
            <th class="simple-table-th">Status</th>
            <!--  <th class="simple-table-th">Created Time</th> -->
          </tr>
        </thead>
        <tbody>
          <template v-if="rowData.length">
            <tr
              v-for="(row, index) in rowData"
              :key="index"
              v-on:click="routeToInvoice(row)"
              class="alternate-listing-row"
            >
              <td class="simple-table-td">
                <Barcode :barcode="row.barcode"></Barcode>
                <v-tooltip
                  v-if="row.recurring_cycles > 0"
                  top
                  content-class="custom-top-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="font-weight-600 custom-blue-border text-uppercase justify-center my-0 font-10 ml-1"
                      color="chip-custom-blue"
                      label
                      outlined
                    >
                      <span style="color: red">{{ row.recurring_cycles }}</span>
                      /
                      <span style="color: green">{{
                        row.recurring_count
                      }}</span>
                    </v-chip>
                  </template>
                  <span>No of Recurring / Total Schedule</span>
                </v-tooltip>
              </td>
              <td class="simple-table-td">
                <template
                  v-if="
                    row?.quotation?.barcode ||
                    row?.milestone?.barcode ||
                    row?.enquiry?.barcode
                  "
                >
                  <div class="my-0">
                    <v-chip
                      small
                      v-if="row?.quotation?.barcode"
                      class="text-white p-3 cursor-pointer mt-1"
                      color="cyan"
                      label
                      outlined
                    >
                      {{ row?.quotation?.barcode }}
                    </v-chip>
                  </div>
                  <div class="my-0">
                    <v-chip
                      small
                      v-if="row?.milestone?.barcode"
                      class="text-white p-3 cursor-pointer mt-1"
                      color="orange"
                      label
                      outlined
                    >
                      {{ row?.milestone?.barcode }}
                    </v-chip>
                  </div>
                  <div class="my-0">
                    <v-chip
                      small
                      v-if="row?.enquiry?.barcode"
                      class="text-white p-3 cursor-pointer mt-1"
                      color="orange"
                      label
                      outlined
                    >
                      {{ row?.enquiry?.barcode }}
                    </v-chip>
                  </div>
                  <div class="my-0">
                    <v-chip
                      small
                      v-if="row?.visit_relation?.barcode /*  && false */"
                      class="text-white p-3 cursor-pointer mt-1"
                      color="cyan"
                      label
                      outlined
                    >
                      {{ data?.visit_relation?.barcode }}
                    </v-chip>
                  </div>
                </template>
                <template v-else> --</template>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Invoice Subject: </b>{{ row.title }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Reference: </b>
                  <template v-if="row.reference">{{ row.reference }}</template>
                  <template v-else
                    ><em class="text--secondary">No Reference</em></template
                  >
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Payment Due: </b>
                  <template v-if="lodash.isEmpty(row.payment_due)"
                    ><em class="text--secondary">No Payment Due</em></template
                  >
                  <template v-else>{{ row.payment_due.text }}</template>
                </p>
              </td>
              <td class="simple-table-td">
                <div class="invoice-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Due Date: </b>
                    <template v-if="row.due_date">{{
                      formatDate(row.due_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Due Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sent Date: </b>
                    <template v-if="row.sent_date">{{
                      formatDate(row.sent_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Sent Date</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Issued Date: </b>
                    <template v-if="row.invoice_date">{{
                      formatDate(row.invoice_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Issued Date</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="invoice-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Payable: </b>
                    <label class="m-0 red--text">{{
                      $accountingJS.formatMoney(row.total)
                    }}</label>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Paid: </b>
                    <label class="m-0 green--text">{{
                      $accountingJS.formatMoney(row.paid_amount)
                    }}</label>
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.pending_amount > 0"
                  >
                    <b>Balance: </b>
                    <label class="m-0 orange--text">{{
                      $accountingJS.formatMoney(row.pending_amount)
                    }}</label>
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <CustomStatus
                  small
                  :status="row.status"
                  endpoint="invoice/status"
                ></CustomStatus>
                <div>
                  <v-chip
                    label
                    color="#a3150b"
                    text-color="white"
                    class="ml-2 text-uppercase font-weight-600"
                    small
                    v-if="row?.is_bad_debt == 1"
                  >
                    Bad Debt
                  </v-chip>
                </div>
              </td>
              <td class="simple-table-td" v-if="false">
                <TableActivity v-if="!lodash.isEmpty(row.added_by)" :data="row">
                  <template v-slot:display_name>
                    {{ row.added_by.display_name }}
                  </template>
                  <template v-slot:date_time>
                    {{ row.created_at }}
                  </template>
                  <template v-slot:format_date_time>
                    {{ formatDateTime(row.added_at) }}
                  </template>
                </TableActivity>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no invoice at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="invoiceLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </div>
</template>

<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import DateRangePicker from "@/view/components/DateRangePicker";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      invoiceLoading: false,
      rowData: [],
      dates: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeId() {
      this.$nextTick(() => {
        this.getInvoices();
      });
    },
  },
  methods: {
    routeToDetailLink(id, type) {
      this.$router.push({
        name: type,
        params: { id },
      });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getInvoices();
    },
    getInvoices() {
      const data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId,
        dates: this.dates,
      };

      if (this.invoiceLoading) {
        return false;
      }

      this.invoiceLoading = true;

      this.$store
        .dispatch(QUERY, { url: "invoice", data: data })
        .then(({ data }) => {
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.currentPage = data.current_page;
          this.rowData = data.rows;
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.invoiceLoading = false;
        });
    },
    routeToInvoice(row) {
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("invoice.detail", {
            params: {
              id: row.id,
            },
          })
        );
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
  },
  mounted() {
    this.getInvoices();
  },
  components: {
    Barcode,
    TableActivity,
    CustomStatus,
    ListingFooter,
    ListingTable,
    DateRangePicker,
  },
};
</script>
